import React, { useEffect, useState } from 'react';
import s from './HeaderAdmin.module.css';
import { NavLink } from "react-router-dom";
import { ROUTER } from '../../config';
import logo from '../../assets/img/logos/logo.svg';
import icon from '../../assets/icons/version_visually_impaired.svg';
import { useDispatch } from 'react-redux';
import { login, logout } from 'store/slice/auth';
import { useGetAuth, useLocalStorage } from 'utils';


const HeaderAdmin = (props) => {

   const auth = useGetAuth(ROUTER.admin.login, false)
   const dispatch = useDispatch()

   const [storedAuth, setAuth] = useLocalStorage('isAuth', false);
   const [storedLogin, setLogin] = useLocalStorage('login', '')

   useEffect(() => {
      const data = {};
      data.isAuth = storedAuth
      data.login = storedLogin


      if (storedAuth === true) {
         dispatch(login(data))
      }

   }, [auth.isAuth])

   const handlerLogout = () => {
      setAuth(false)
      setLogin('')
      dispatch(logout())
   }

   return (
      <div >
         <div className={`bgMainAdmin ${s.bgBlock}`}>
            <div className={s.logoRow}>
               <NavLink to={ROUTER.main} className={s.logoBlock}>
                  <img src={logo} alt="" />
                  <div className={s.textMain}>
                     <div className={s.titleInfo}>Официальный интернет-сайт</div>
                     <div className={s.titleAdministration}>АДМИНИСТРАЦИИ ГОРОДСКОГО ОКРУГА ХИМКИ</div>
                     <div className={s.titleInfo}>Московской области</div>
                  </div>
               </NavLink>
               <div className={s.settingsContainer}>
                  {auth.isAuth && <div className={s.welcomeMessage}>Добро пожаловать, {auth.user.login}</div>}
                  {auth.isAuth && <button onClick={handlerLogout} className='oval ml20'>Выйти</button>}
               </div>
            </div>
         </div>

      </div >


   )
}
export default HeaderAdmin;