import React from 'react';
import ContantContainerMain from '../../total/ContantContainerMain';
import MenuAdmin from "../../ComponentsAdmin/MenuAdmin";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import s from './MainContainerAdmin.module.css';

import HeaderAdmin from "../../ComponentsAdmin/HeaderAdmin";

const MainContainerAdmin = (props) => {
   return (
      <div>
         <HeaderAdmin /* setActive={setVisuallyImpairedActive} active={visuallyImpairedActive} */ />
         
         <div className="columnContainer mt40" >
            <MenuAdmin />

            <Outlet />

            {/* <div className="columnLarge">
            <ContantContainerMain>

               Административная паннель

            </ContantContainerMain>
         </div> */}
         </div>
      </div>
   )
}
export default MainContainerAdmin;