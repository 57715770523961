import React, { useEffect, useState } from "react";
import s from "./DeputiesPageList.module.css";
import API from "../../API";
import { NavLink } from "react-router-dom";
import { ROUTER } from "../../config";
import ContantContainerAdmin from "../../total/ContantContainerAdmin";
import ItemComponent from "../ItemComponent";
import PaginationComponent from "../../total/PaginationComponent";

const DeputiesPageList = () => {
   const [deputies, setDeputies] = useState([]);
   const [checkbox, setCheckbox] = useState(false);

   return (
      <div className="columnContainer mt40" >

         <div className="columnLarge">
            <ContantContainerAdmin>
               <div className={`breadcrumbs`}>
                  {/* <NavLink to={ROUTER.main} className="breadcrumbsFrom">
            Главная
          </NavLink> */}
                  <span className={"breadcrumbsTo"}>  Депутаты</span>
               </div>
               <div className={s.container}>
                  <div className='titleRowBlock'>
                     <div className='checkboxBlock'>
                        <input
                           onChange={() => setCheckbox(!checkbox)}
                           value={checkbox}
                           className="mainInput"
                           type="checkbox"
                           name="scales"
                        />
                     </div>
                     <div className='titleBlock'>Заголовок</div>
                     <div className='publishedBlock'>Опубликовано</div>
                     <div className='dateBlock'>Дата публикации</div>
                  </div>
                  <div>
                     {deputies?.deputat?.list?.map((el) => (
                        <ItemComponent key={el.id} id={el.id} name={el.name} published={true} date={el.created_at} checkboxMain={checkbox} />
                     ))}
                  </div>

                  <PaginationComponent getDataBack={API.getDeputaty} setData={setDeputies} getData={deputies} getDataBackKey={"deputat"} />

               </div>
            </ContantContainerAdmin >
         </div>

         <div className="columnSmal">
            <NavLink to={ROUTER.admin.deputiesArticle} className="publishBtn">Добавить депутата</NavLink>
         </div>

      </div>
   );
};

export default DeputiesPageList;
