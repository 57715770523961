import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface User {  // Определяем интерфейс для User
   id: number;
   login: string;
   password: string;
}

interface AuthState {
   isAuth: boolean;
   user: User | {};
}

const initialState = {
   user: {},
   isAuth: false
}

export const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {
      login(state, action: PayloadAction<User>) {
         state.user = action.payload;
         state.isAuth = true;

         /* console.clear()
         console.log('Action', action.payload)
         console.log('User from state', state.user)
         console.log('Login ', state.isAuth) */
      },
      logout(state) {
         state.isAuth = false;
         state.user = {};
     },
   }
})

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;