import React, { useState } from 'react';
import s from './UploadFileAdmin.module.css';
import IconWordPdfText from '../../Components/IconWordPdfText';


const UploadFileAdmin = ({ handler, title, type, fileName, discription }) => {

   const [files, setFiles] = useState(null);

   const handleFileChange = (e) => {
      console.log(e.target.files)
      if (type === "multy") {
         if (e.target.files) {
            handler(e.target.files[0])
            setFiles(e.target.files[0]);
         }
      } else handler(e.target.files[0])
   };

   return (
      <div className={s.containerUpload}>

         <div className={s.fileUpload}>
            <div className={s.uploadBox}>
               <label htmlFor="fileupload" className={s.uploadBtn}>{title}</label>
               <input
                  type="file"
                  id="fileupload"
                  className={s.dN}
                  onChange={handleFileChange}
                  accept=".doc, .docx, .pdf, .jpeg, .jpg, .png"
               />
            </div>
         </div>
         {type === "multy" &&
            <div className="mt48">
               {
                  files ? (
                     <div className={s.fileContainer}>
                        {
                           files.name?.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                              <IconWordPdfText type={'IMG'} /> :
                              files.name?.match(/.(doc|docx)$/i) ? <IconWordPdfText type={'DOCX'} /> :
                                 files.name?.match(/.(PDF)$/i) ? <IconWordPdfText type={'PDF'} /> :
                                    <IconWordPdfText type={'anotherFile'} />
                        }
                        <div className={s.fileInfoBlock}>
                           {fileName.length ? fileName : "Заголовок документа"} <br />
                           {discription.length ? discription : "Описание"}
                           {/* <div type="button" className={s.deleteBtn} onClick={DeleteSelectFile}>Удалить</div> */}
                        </div>
                     </div>
                  ) : false

               }
            </div>
         }

      </div>
   );
}
export default UploadFileAdmin;