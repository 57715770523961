import React, {useState, useRef, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function maskInput(value, mask) {
   var literalPattern = /[0\*]/;
   var numberPattern = /[0-9]/;
   var newValue = "";
   try {
      var maskLength = mask.length;
      var valueIndex = 0;
      var maskIndex = 0;

      for (; maskIndex < maskLength;) {
         if (maskIndex >= value.length) break;

         if (mask[maskIndex] === "0" && value[valueIndex].match(numberPattern) === null) break;

         // Found a literal
         while (mask[maskIndex].match(literalPattern) === null) {
            if (value[valueIndex] === mask[maskIndex]) break;
            newValue += mask[maskIndex++];
         }
         newValue += value[valueIndex++];
         maskIndex++;
      }

   } catch (e) {
      console.log(e);
   }
   return newValue
}


export const getDate = (date) => {
   const [year, month, day] = date.split("-");
   return `${day}.${month}.${year}`;
};


export function useDebounce(func, delay, cleanUp = false) {
   const timeoutRef = useRef();

   function clearTimer() {
      if (timeoutRef.current) {
         clearTimeout(timeoutRef.current);
         timeoutRef.current = undefined;
      }
   }

   useEffect(() => (cleanUp ? clearTimer : undefined), [cleanUp]);

   return (...args) => {
      clearTimer();
      timeoutRef.current = setTimeout(() => func(...args), delay);
   };
}

export function useGetAuth(url, checkAuth) {
   const auth = useSelector(state => state.auth)

   const navigate = useNavigate()

   useEffect(() => {
      checkAuth ? auth.isAuth && navigate(url) : auth.isAuth || navigate(url)
   }, [auth.isAuth])

   return auth;
}

export function useLocalStorage(key, initialValue) {
   // Инициализация состояния с использованием данных из localStorage
   const [storedValue, setStoredValue] = useState(() => {
     try {
       const item = window.localStorage.getItem(key);
       return item ? JSON.parse(item) : initialValue;
     } catch (error) {
       console.error('Ошибка при чтении из localStorage', error);
       return initialValue;
     }
   });
 
   // Функция для обновления состояния и localStorage
   const setValue = (value) => {
     try {
       const valueToStore = value instanceof Function ? value(storedValue) : value;
       setStoredValue(valueToStore);
       window.localStorage.setItem(key, JSON.stringify(valueToStore));
     } catch (error) {
       console.error('Ошибка при записи в localStorage', error);
     }
   };
   return [storedValue, setValue];
 }